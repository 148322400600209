/* All media queries */
.page-header{
  border-bottom: 1px solid $site_blue !important;
}

.cal-list-group{
  .active{
    background: $site_blue;
    &:hover{
      background: darken($site_blue, 10%);
    }
  }
}

.cal-panel-blue{
  border: 1px solid $site_blue;
}

.cal-heading{
  background: $site_blue !important;
  color: white !important;
}

.cal-label{
  background: $site_blue;
  color: white;
  &:hover,&:visited{
    background: darken($site_blue, 10%);
    color: white;
  }
  &:active, &:focus{
    background: darken($site_blue, 10%) !important;
    color: white !important;
  }
}

.pager li > a, .pager li > span{
  background: $site_blue;
  color: white;
  &:hover,&:visited{
    background: darken($site_blue, 10%);
    color: white;
  }
  &:active, &:focus{
    background: darken($site_blue, 10%) !important;
    color: white !important;
  }
}

.cal-top-offset{

}

/* Small devices (tablets, 768px to 992px) */
@media (min-width : $screen-sm) {
  .cal-sidebar-left{
    margin-top: 100px;
  }
}

/* Medium devices (laptops, 992px to 1199px) */
@media (min-width : $screen-md) {
  .cal-sidebar-left{
    margin-top: 150px;
  }
}

