/* Styles Variables */

// Media Query Breakpoints
$screen-sm : 768px;
$screen-md : 992px;
$screen-lg : 1200px;

//font imports
@font-face{
  font-family: 'pt-sans';
  src: url('../../assets/fonts/pt-sans/PTS55F-webfront.eot');
  src: url('../../assets/fonts/pt-sans/PTS55F-webfront.eot?iefix') format('eot'),
  url('../../assets/fonts/pt-sans/PTS55F-webfront.woff') format('woff'),
  url('../../assets/fonts/pt-sans/PTS55F-webfront.ttf') format('truetype'),
  url('../../assets/fonts/pt-sans/PTS55F-webfront.svg#webfont') format('svg');
}

@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,300,200);

//fonts vars
$font_family--pt: 'pt-sans', sans-serif;
$font_family--source: 'Source Sans Pro', sans-serif;

//general vars
$site_blue: #151977;
$site_orange: #f89421;
$site_brown: #d6cdb4;
$site_green: #119747;