$text-size--xs: 14px;
$text-size--sm: 18px;
$text-size--md: 22px;

.nwtdd{
  footer{
    background: $site_green !important;
  }
}

footer {
  background         : $site_orange;
  padding-bottom: 75px;
}

.footer_logo{
  img{
    display: block;
    margin: auto;
  }
}

.footer_contact{
  text-align: center;
  color: white;
  font-size: $text-size--xs;
  padding-top: 15px;

  &-address{
    margin: 0;
    padding-bottom: 5px;
  }

  &-phone{
    padding-bottom: 15px;
    a{
      color: white;
      &:hover{
        color: darken(white, 10%);
      }
    }
  }

  &-links{
    a{
      color: white;
      text-decoration : underline;

      &:hover{
        color: darken(white, 10%);
      }
    }
  }
}

/* Small devices (tablets, 768px to 992px) */
@media (min-width : $screen-sm) {
  footer {
    background         : $site_orange;
    padding-bottom: 75px;
  }

  .footer_logo{
    img{
      display: block;
      margin: auto;
    }
  }

  .footer_contact{
    //text-align: center;
    //color: white;
    padding-top: 30px;
      font-size: $text-size--sm;
      font-weight: 100;

    &-address{
      //margin: 0;
      //padding-bottom: 5px;
    }

    &-phone{
      //padding-bottom: 15px;
      a{
        //color: white;
        &:hover{
          //color: darken(white, 10%);
        }
      }
    }

    &-links{
      a{
        //color: white;
        //text-decoration : underline;

        &:hover{
          //color: darken(white, 10%);
        }
      }
    }
  }
}

/* Medium devices (laptops, 992px to 1199px) */
@media (min-width : $screen-md) {
  footer {
    padding-bottom: 75px;
  }

  .footer_logo{
    img{ }
  }

  .footer_contact{
    padding-top: 30px;
    font-size: $text-size--md;
    font-weight: 100;

    &-address{ }

    &-phone{
      a{
        &:hover{ }
      }
    }

    &-links{
      a{
        &:hover{ }
      }
    }
  }
}