/* Medium devices (laptops, 992px to 1199px) */
@media (min-width : $screen-md) {
  .section-heading {
    &--blue {
      font-size   : 50px;
      padding-top : 0;
      margin-top  : 0;
      //font-weight : 200;
      //color: $site_blue;
    }
    &--white {
      font-size   : 50px;
      padding-top : 0;
      margin-top  : 0;
      //font-weight : 200;
      //color: white;
    }
  }

  .regional-events {
    padding : 50px 0;
  }
  .social {
    //background: $site_blue;
    padding : 50px 0;

    &-feed {
      height : 500px;
      //overflow: scroll;
    }
  }

  #map {
    height : 500px;
  }

  .annual-reports {
    padding : 50px 0;
  }

  .main-interior {
    background : $site-brown;
    min-height : 650px;
    &-content {
      color : $site_blue;
    }
  }
  .side-bar {
    padding : 100px 0 30px;
    width   : 250px;
    display : block;
    margin  : auto;
    &-nav {
      li {
        a {
          &:hover { }
        }

        .active { }
      }
    }
  }

  .map-text {
    //position : absolute;
    //z-index : 3;
    //bottom: 0;
    //background : rgba($site_orange,.8);
    //padding    : 15px 0;
    //width: 100%;

    h2 {
      //color       : $site_blue;
      //font-style  : italic;
      //font-weight : 400;
      //margin: 0;
      display    : inline;
      text-align : left;
    }

    a {
      display        : inline-block;
      //margin         : auto;
      margin-top     : 0px;
      //padding        : 15px;
      width          : 15%;
      //text-transform : uppercase;
      //color          : white;
      //font-weight    : 100;
      //border-radius  : 0;
      //@extend .btn;
      //@extend .btn-default;
      //background     : $site_blue;
      //border         : none;
      position: absolute;
      width: 12%;
      margin-left: 30px;
      bottom: 0;
      padding: 30px;
      font-size: 22px;

      &:hover {
        //background : darken($site_blue, 10%);
        //color      : white;
      }

      &:focus, &:active {
        //background : darken($site_blue, 10%) !important;
        //color      : white !important;
      }
    }
  }
}