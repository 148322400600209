$header_bg : $site_blue;
$nav_bg : $site_orange;

$header_font_size--xs : 22px;
$header_font_size--sm : 24px;
$header_font_size--md : 42px;

$link_text_color--default : white;
$link_text_color--hover : darken(white, 5%);
$link_text_bg--hover : darken($site_orange, 5%);

$link_font_size--xs : 14px;
$link_font_size--sm : 16px;
$link_font_size--md : 24px;

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
  display: block;
}

.dropdown-submenu>a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu:hover>a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

.nwthra{
  header{
    background: $site_blue !important;
  }
  .navbar{
    background: $site_orange !important;
  }
}

.nwtdd{
  header{
    background: $site_blue !important;
  }
  .navbar{
    background: $site_green !important;
    &-nav{
      li{
        a{
          &:hover{
            background: darken($site_green, 5%) !important;
          }
        }
      }
    }
  }
}

header {
  background : $header_bg;
  position   : relative;
  z-index    : 2;

  &:after {
    height             : 10px;
    display            : block;
    width              : 100%;
    background         : white;
    content            : '';
    box-shadow         : 0 4px 10px 0px rgba(#1a1a1c, .5);
    -webkit-box-shadow : 0 4px 10px 0px rgba(#1a1a1c, .5);
    -moz-box-shadow    : 0 4px 10px 0px rgba(#1a1a1c, .5);
  }
}

.header {
  &-logo {
    a {
      position : absolute;
      img{
        max-width: 185px;
      }
    }
  }

  &-text {
    a {
      text-decoration : none;
      h1 {
        text-align     : center;
        color          : $link_text_color--default;
        letter-spacing : 5px;
        text-transform : uppercase;
        font-weight    : 100;
        font-size      : $header_font_size--xs;
        margin         : 15px 0;
        @include transition(font-size, 100ms);
      }
    }
  }
}

.navbar {
  border             : none;
  background         : $nav_bg;
  position           : inherit;
  border-radius      : 0;
  margin-bottom      : 0;
  position: relative;
  z-index: 1;
  box-shadow         : 0 4px 10px 0px rgba(#1a1a1c, .5);
  -webkit-box-shadow : 0 4px 10px 0px rgba(#1a1a1c, .5);
  -moz-box-shadow    : 0 4px 10px 0px rgba(#1a1a1c, .5);
  &-header {
    .navbar-toggle {
      right      : 0;
      border     : none;
      background : transparent !important;
      padding    : 15px 0;
      &:hover {
        background : transparent !important;
      }
      .icon-bar {
        width      : 22px;
        transition : all 0.2s;
        background : white;
      }
      .top-bar {
        transform        : rotate(45deg);
        transform-origin : 10% 10%;
      }
      .middle-bar {
        opacity : 0;
      }
      .bottom-bar {
        transform        : rotate(-45deg);
        transform-origin : 10% 90%;
      }
      &.collapsed {
        .top-bar {
          transform : rotate(0);
        }
        .middle-bar {
          opacity : 1;
        }
        .bottom-bar {
          transform : rotate(0);
        }
      }
    }
    .navbar-brand {
      position : absolute;
      z-index  : 3;
      top      : 0;
      padding  : 0;
    }
  }
  &-collapse {
    .navbar-nav {
      margin : 0 -15px;
      li {
        a {
          color       : $link_text_color--default;
          padding     : 15px;
          font-size   : $link_font_size--xs;
          font-weight : 200;
          text-transform : uppercase;
          @include transition(background, 100ms);
          &:hover, &:visited {
            background : $link_text_bg--hover;
            color      : $link_text_color--hover;
          }
        }
      }
      .active {
        a {
          background : darken($site_orange, 8%);
          color      : $link_text_color--default;
          &:hover, &:visited {
            background : $link_text_bg--hover;
            color      : $link_text_color--hover;
          }
        }
      }
    }
  }
}

/* Tablet and up */
@media (min-width : $screen-sm) {
  header {
    &:after { }
  }

  .header {
    &-logo {
      a {
        img{
          max-width: 100%;
        }
      }
    }

    &-text {
      a {
        h1 {
          text-align : left;
          font-size  : $header_font_size--sm;
        }
      }
    }
  }

  .navbar {
    &-header {
      .navbar-brand { }
    }
    &-collapse {
      .navbar-nav {
        li {
          a {
            color     : $link_text_color--default;
            font-size : $link_font_size--sm;
            @include transition(background, 100ms);
            &:hover, &:visited { }
          }
        }
        .active {
          a {
            &:hover, &:visited { }
          }
        }
      }
    }
  }
}

/* Medium devices (laptops, 992px to 1199px) */
@media (min-width : $screen-md) {
  header {
    &:after { }
  }

  .header {
    &-logo {
      a { }
    }

    &-text {
      a {
        h1 {
          font-size : $header_font_size--md;
        }
      }
    }
  }

  .navbar {
    &-header {
      .navbar-brand { }
    }
    &-collapse {
      .navbar-nav {
        li {
          a {
            font-size : $link_font_size--md;
            letter-spacing : 2px;
            &:hover, &:visited { }
          }
        }
        .active {
          a {
            &:hover, &:visited { }
          }
        }
      }
    }
  }
}