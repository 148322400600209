/* Mobile Styles (width < 768px)*/
.section-heading{
  &--blue{
    font-size   : 32px;
    font-weight : 200;
    color: $site_blue;
  }
  &--white{
    font-size   : 32px;
    font-weight : 200;
    color: white;
  }
}

.social {
  background     : $site_blue;

  &-feed {
    .feed{
      height   : 400px;
      overflow : scroll;
    }
  }
}

.social-panel-group{
  margin: 0;
  padding: 50px 0;

  .panel-heading{
    padding: 20px;
    background: white;
  }
  .panel-title{
    color: $site_blue;
    a{
      text-decoration: none;
    }
    h2{
      font-size: 24px;
    }
    i{
      float : right;
    }
  }

  .social-panel {
    height: 350px;
    overflow: scroll;
  }
}

.annual-reports {
  position : relative;
  z-index : 1;
  -webkit-box-shadow : 0 8px 15px -5px rgba(#1a1a1c, .5);
  -moz-box-shadow    : 0 8px 15px -5px rgba(#1a1a1c, .5);
  box-shadow         : 0 8px 15px -5px rgba(#1a1a1c, .5);
  h2 {
    color : $site_blue;
  }

  ul {
    li {
      padding : 10px 0;
      a {
        color       : $site_blue;
        font-size   : 16px;
        font-weight : 200;
      }
    }
  }
}

.map {
  position : relative;
  //z-index : 5;
  box-shadow         : 0 4px 10px 0px rgba(#1a1a1c, .5);
  -webkit-box-shadow : 0 4px 10px 0px rgba(#1a1a1c, .5);
  -moz-box-shadow    : 0 4px 10px 0px rgba(#1a1a1c, .5);
  &-area{
    height:450px;
    width: 100%;
    &:after{
      height: 10px;
      display: block;
      width: 100%;
      background: $site_blue;
      //border-right: 1px red;
      content: '';
      position: absolute;
      z-index: 5;
      bottom: 0;
    }
  }
  &-text {
    position : absolute;
    z-index : 3;
    bottom: 0;
    background : rgba($site_orange,.8);
    padding    : 15px 0;
    width: 100%;

    h2 {
      color       : $site_blue;
      font-style  : italic;
      font-weight : 400;
      margin: 0;
      display: block;
      text-align: center
    }

    a {
      display : inline;
      display: block;
      margin: auto;
      margin-top: 15px;
      padding: 15px;
      width: 50%;
      text-transform : uppercase;
      color: white;
      font-weight: 100;
      border-radius: 0;
      @extend .btn;
      @extend .btn-default;
      background: $site_blue;
      border: none;

      &:hover{
        background: darken($site_blue, 10%);
        color: white;
      }

      &:focus, &:active{
        background: darken($site_blue, 10%) !important;
        color: white !important;
      }
    }
  }
}

.regional-events{
  background : $site_brown;
  padding: 30px 0;
  h2{
    margin-top: 0;
  }
  .more-events{
    color: $site-blue;
    font-size: 18px;
    &:hover{
      color: darken($site_blue, 10%);
    }
  }
}

.event{
  font-size: 18px;
  padding-bottom: 15px;
  &-link{
    display : block;
    color: $site_blue;
    &:hover{
      color: darken($site_blue, 10%);
    }
  }
  &-date{
    display : block;
    color: #5f5f5f;
  }
}

.main-interior{
  background: $site-brown;
  min-height: 500px;
  position : relative;
  z-index: 1;
  box-shadow         : 0 4px 10px 0px rgba(#1a1a1c, .5);
  -webkit-box-shadow : 0 4px 10px 0px rgba(#1a1a1c, .5);
  -moz-box-shadow    : 0 4px 10px 0px rgba(#1a1a1c, .5);
  &-content{
    color: $site_blue;
    padding-bottom: 50px;
  }
}