/* General Styles */
*{
  font-family: $font_family--pt;
}

input{
  @extend .form-control;
  width: 100% !important;
}

select{
   @extend .form-control;
  width: 100% !important;
}

textarea{
  @extend .form-control;
  width: 100% !important;
}

input[type='submit']{
  display: inline;
  width: auto !important;
}

input[type="checkbox"] {
  margin : -8px 0 0;
}

.img-shadow{
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);
}

.img-right{
  float: right;
  margin-left: 12px;
  margin-bottom: 12px;
}

.img-left{
  float: left;
  margin-right: 12px;
  margin-bottom: 12px;
}

.crt-post {
  .crt-post-header, .crt-post-share {
    background-color: #fffefe;
    color: #2B2B2B;
  }
  .crt-post-header {
    .social-icon {
      background-color: #fffefe;
    }
    .crt-post-name a {
      color: #2B2B2B;
    }
  }
  .crt-post-share a, .crt-post-header .social-icon i {
    color: #2B2B2B;
  }
  background-color: #fffefe;
  color: #707070;
  .crt-post-content-text {
    background-color: #fffefe;
    color: #707070;
  }
}

.crt-feed, .crt-feed-more {
  background-color: #151977 !important;
}

.staff-row{
  margin-bottom: 15px;
  a{
    color: $site_blue !important;
  }

  &:after{
    content:' ';
    display:block;
    border:1px solid $site_blue;
    width: 90%;
    margin-left: 2%;
  }
}

.staff-heading{
  margin-top: 30px;
}

.nwtdd {
  .side-bar-nav {
    .active {
      background : darken($site_green, 8%) !important;
    }

    ul {
      list-style-type : none;
      padding-left    : 15px;
    }
  }
}

.nwthra {
  .side-bar-nav {
    .active {
      background : darken($site_orange, 8%) !important;
    }

    ul {
      list-style-type : none;
      padding-left    : 15px;
    }
  }
}

.resource_grid{
  margin-bottom: 15px;
}

.resource_btn{
  @extend .btn;
  @extend .btn-default;
  @extend .btn-block;

  background: $site_blue;
  color: white;
  padding: 15px 5px;
  white-space: pre-wrap;

  &:hover{
    background: lighten($site_blue, 5%) !important;
    color: white !important;
  }

  &:active, &:visited{
    background: $site_blue !important;
    color: white !important;
  }
}
