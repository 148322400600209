/* Small devices (tablets, 768px to 992px) */
@media (min-width : $screen-sm) {
  .nwtdd{
    .side-bar {
      background : $site_green !important;
      &-nav {
        li {
          a {
            &:hover{
              background: darken($site_green, 10%) !important;
            }
          }
          .active{
            background: darken($site_green, 5%) !important;
          }
        }
      }
    }
  }


  .section-heading {
    &--blue {
      font-size : 48px;
      //font-weight : 200;
      //color: $site_blue;
    }
    &--white {
      font-size : 48px;
      //font-weight : 200;
      //color: white;
    }
  }

  .social {
    //background: $site_blue;
    padding-bottom : 50px;

    &-feed {
      height : 500px;
      //overflow: scroll;
    }
  }

  .regional-events {
    background : $site_brown;
    padding    : 30px 0;
    h2 {
      margin-top : 0;
    }
    .more-events {
      color     : $site-blue;
      font-size : 18px;
      &:hover {
        color : darken($site_blue, 10%);
      }
    }

    img {
      margin-top : 30px;
    }
  }

  .event {
    font-size      : 18px;
    padding-bottom : 15px;
    &-link {
      display : block;
      color   : $site_blue;
      &:hover {
        color : darken($site_blue, 10%);
      }
    }
    &-date {
      display : block;
      color   : #5F5F5F;
    }
  }

  .main-interior{
    background: $site-brown;
    min-height: 650px;
    &-content{
      color: $site_blue;
    }
  }
  .side-bar {
    background : $site_orange;
    padding: 50px 0 30px;
    &-nav {
      li {
        a {
          color : white;
          font-family: $font_family--source;
          font-weight: 300;
          display: block;
          padding: 12px 30px;
          @include transition(background, 200ms);

          &:hover{
            text-decoration: none;
            background: darken($site_orange, 10%);
          }
        }

        .active{
          background: darken($site_orange, 5%);
        }
      }
    }
  }

  .map-text {
    //position : absolute;
    //z-index : 3;
    //bottom: 0;
    //background : rgba($site_orange,.8);
    //padding    : 15px 0;
    //width: 100%;

    h2 {
      //color       : $site_blue;
      //font-style  : italic;
      //font-weight : 400;
      //margin: 0;
      display    : inline;
      text-align : left;
    }

    a {
      display        : inline-block;
      //margin         : auto;
      margin-top     : 0px;
      //padding        : 15px;
      width          : 30%;
      //text-transform : uppercase;
      //color          : white;
      //font-weight    : 100;
      //border-radius  : 0;
      //@extend .btn;
      //@extend .btn-default;
      //background     : $site_blue;
      //border         : none;

      &:hover {
        //background : darken($site_blue, 10%);
        //color      : white;
      }

      &:focus, &:active {
        //background : darken($site_blue, 10%) !important;
        //color      : white !important;
      }
    }
  }
}